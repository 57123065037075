header {
    height: 90px;
    padding-left: 30px;
    padding-right: 30px;
    border: 5px solid #fbfafa;
}

.sec_container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.top_small_txt {
    font-size: 1.15rem;
    color: #999;
    text-align: center;
    margin: 0;
}

.logo {
    /* padding: 20px 0; */
    max-width: 100%;
    width: 25%;
    position: relative;
    /* font-size: 40px;
    font-weight: 900; */
}

.logo img {
    max-width: 100%;
}

@media (max-width: 600px) {
    .logo {
        width: 60%;
    }
}