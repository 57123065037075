.main_container {
    background-color: #f7f6ff;
    height: 100%;
    padding: 0px 0px 20px 0px;
}

.main_sec_container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 40px;
    height: auto;
}

.hideButton {
    display: none;
}

@media (max-width: 600px) {
    .main_sec_container {
        padding: 20px;
    }
}