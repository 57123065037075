@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


h1 {
    font-size: 3.0rem;
    font-weight: 800 !important;
    line-height: 2.0;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    /* font-family: 'Playfair Display', serif; */
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

p {
    font-size: 1.0em;
    margin: 20px auto;
    text-align: left;
    line-height: 1.55em;
    letter-spacing: normal;
    color: #3a3838;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
}

li {
    padding: 5px 0px;
}

    /* Custom All Version CSS Override */

.intro_text span {
    font-size: 24px;
    font-weight: bold;
    background: #f2ff7e;
    padding: 3px 25px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 25px;
}

.intro_details {
    justify-content: left !important;
}

br {
    display: block;
    content: "";
    line-height: 1.2em;
    margin: 15px;
}

button {
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    border: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
}

.main_container {
    background-color: #f9fbfa;
}

.offer_image img {
    margin: 10px auto;
}



@media (max-width: 600px) {
    p {
        font-size: 18px;
        margin: 20px auto 5px auto;
        text-align: left;
        font-weight: 400;
        line-height: 1.80rem;
        letter-spacing: normal;
        color: #3a3838;
    }

    h1 {
        font-size: 2.4rem !important;
    }

    .offer_post h2, h2 {
        line-height: 35px !important;
        font-size: 25px !important;
        text-transform: capitalize !important;
    }

    .button {
        padding: 14px 15px !important;
    }
}



