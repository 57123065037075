.main_container {
    background-color: #f9fbfa;
    height: 100%;
    padding: 0px 0;
}

.main_sec_container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 20px;
    height: auto;
}

.hideButton {
    display: none;
}

@media (max-width: 320px) {
    .main_sec_container {
        padding: 18px;
    }
}